@charset "UTF-8";
/* CSS DOC */

/* MOBILE //////////////////////////////////////////////////// */

@media only screen and (max-width: 700px) {
  #dashboard {
    display: block !important;
  }
}

/* TABLET //////////////////////////////////////////////////// */

@media only screen and (max-width: 1000px) {
  #dashboard {
    display: inline !important;
  }
}

/* DESKTOP //////////////////////////////////////////////////// */

@media only screen and (min-width: 1000px) {
  #dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: inherit;
  }
}

#dashboard a {
  text-decoration: none;
  color: initial;
}
.dial {
  position: relative;
  font-family: "Poppins", serif !important;
  transition-duration: 0.35s;

  margin: 35px 10px;
  z-index: 1;
}

.dial span {
  font-family: "Poppins", serif !important;

  font-weight: 500 !important;
  transition: ease-in-out;
  transition-duration: 0.35s;
  display: block;
  height: 30px;
  font-size: 18px;
  z-index: 2;
  color: var(--dark);
}

.dial:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: ease-in-out;
  transition-duration: 0.35s;
  font-size: 26px;
}

.dial:hover .dial span {
  font-size: 26px !important;
  height: 50px !important;
}

hr {
  height: 2px;
  border-width: 0;
  color: var(--dark);
  background-color: var(--dark);
}

.dial:hover hr {
  transform: rotate(-3deg);
  transition: ease-in-out;
  transition-duration: 0.35s;
}

.dial svg {
  color: var(--dark);
}

.dashIcon {
  position: absolute;
  right: 20px;
  top: -20px;
  transform: rotate(-5deg);
}

.dial:hover .dashIcon {
  transition-duration: 1.25s;
  transform: rotate(360deg);
}
.dial:blur {
  transition-duration: 2s !important;
  transform: scale(1) !important;
}

.dial:blur .dashIcon {
  transform: rotate(360deg);
}

.dashDesc {
  opacity: 0;
  margin: 20px 0px 30px 0px;
  font-size: 15px;
  transition-duration: 1s;
  color: var(--dark);
}

.dial:hover .dashDesc {
  display: block;
  opacity: 1;
}

.dialBG {
  position: absolute;
  top: -35px;
  left: 20px;
  transition-duration: 0.35s;
  width: 240px;
  z-index: -1;
}

.dial:hover .dialBG {
  transform: rotate(20deg);
  transform: scale(1.5);
}

/* presentFile */
#dashboard .column4 {
  z-index: 1;
}

#dashboard .column4:hover {
  z-index: 10000;
}

.presentFile .dialBG {
  left: -25px !important;
  top: -50px !important;
  width: 225px;
}

/* sendFile */
.sendFile .dialBG {
  transform-origin: center;
  left: -15px !important;
  top: -40px !important;
}
.sendFile:hover .dialBG {
  left: 20px !important;
  top: -30px !important;
}
.sendFile:hover {
}

/* compressPres */

.compressFile .dialBG {
  left: -15px !important;
  top: -40px !important;
  width: 250px;
}

.compressFile:hover .dialBG {
  left: 15px !important;
  top: -30px !important;
  transform: scale(1.3);
}

.compressFile:hover .dialBG {
  transform: scale(1.5);
  left: -25px !important;
}
/* makeCall */

.makeCall .dialBG {
  left: -40px;
  top: -80px;
}
.makeCall:hover .dialBG {
  left: -20px !important;
  top: -85px !important;
  transform: scale(1.5);
}
