@charset "UTF-8";
/* CSS DOC */

/* MOBILE //////////////////////////////////////////////////// */

@media only screen and (max-width: 700px) {
  .aboutHeader h3 {
    height: 65px;
    margin-top: 65px;
    margin-bottom: 65px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  .dashboardCon {
    max-width: 300px;
    margin: auto;
  }
}

/* TABLET //////////////////////////////////////////////////// */

@media only screen and (min-width: 700px) {
  .aboutHeader h3 {
    margin-top: calc((((100vh - 65px - 65px) / 2) - 65px) - 165px);
  }
}

/* DESKTOP //////////////////////////////////////////////////// */

@media only screen and (min-width: 1000px) {
  .aboutHeader h3 {
    margin-top: calc((((100vh - 65px - 65px) / 2) - 65px) - 165px);
  }
}

.aboutHeader {
  text-align: center;
  padding: 0px !important;
}

.aboutHeader h3 {
  height: 65px;
  margin-bottom: 65px;
}

.dashboardCon {
  padding: 0px;
  overflow: hidden;
  height: calc((100vh - 65px - 65px) / 2);
  overflow: initial;
}

.beta {
  text-align: center;
  height: 65px;
  line-height: 65px !important;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-family: "Poppins", serif !important;
  color: var(--dusk);
  padding: 0px !important;
}
