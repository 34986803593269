@charset "UTF-8";
/* CSS DOC */
body {
  overscroll-behavior-x: none;
}
.thumb {
  display: inline-block;
  width: auto;
  height: auto;
  cursor: pointer;
}

.thumb img {
  background-color: #fff !important;
}

.thumbSmart {
  /* width: 100%;
  height: 300px; */
  background: var(--mid) !important;
  text-align: center;
  color: var(--dark);
}

/* ///////////////////////////////////////////////////////////////////////////// */
/* // PROJECT */

.projectThumbs {
  margin-bottom: 30px;
  width: 100%;
  height: 170px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 3px;
}
.projectThumbs .thumbSettings {
  display: none;
}

.projectThumbs .thumb img {
  height: 150px;
  border: 2px solid var(--white);
}

.projectThumbs .thumb:hover img {
  border: 2px solid var(--light);
}

.projectThumbs .selected {
  border: 2px solid var(--blue) !important;
}

.projectThumbs .thumbSmart {
  width: 225px;
  line-height: 150px;
  vertical-align: top;
  height: 150px;
  float: left;
  border: 2px solid var(--white);
  background-color: var(--white);
}

.projectThumbs .thumbSmart:hover {
  border: 2px solid var(--mid);
}

.thumbConBlank {
  background: var(--light);
  text-align: center;
  line-height: 150px;
}
.thumbBlank {
  background: var(--light);
  height: 150px;
  line-height: 150px;
  width: 100%;
  text-align: center;
}

.thumbBlank:hover {
  /* background: var(--red); */
  color: var(--dusk);
}

.thumbBlank a:hover {
  /* background: var(--red); */
  color: var(--dusk);
}

.projectOverview {
  text-decoration: none !important;
  margin: 0px 0px 90px 0px;
}

/* ///////////////////////////////////////////////////////////////////////////// */
/* // PRES */

.sortableHelper .thumb {
  width: 100%;
}
.sortableHelper .thumbSmart {
  width: calc(100% - 0px);
  display: block;
  float: left;
}

.sortableHelper .thumbSettings {
  float: left;
}

.sortableHelper .thumbSettings,
#presNav .thumbSettings {
  float: left;
  width: 55px;
  opacity: 0;
}

.sortableHelper .thumbSettings,
#presNav .thumb:hover .thumbSettings {
  opacity: 1;
}

#presNav .selected {
  border: 2px solid var(--blue) !important;
  background-color: var(--blue);
}

#presNav .thumb {
  width: 100%;
  margin-bottom: 6px;
}

.sortableHelper img,
#presNav .thumb img {
  width: calc(100% - 60px);
  border: 2px solid var(--dark);
  background-color: var(--dark);
}

.sortableHelper .thumbSmart,
#presNav .thumbSmart {
  width: calc(100% - 60px);
  border: 2px solid var(--dark);
  background-color: var(--dark);
  float: left;
  line-height: 200px;
}

#presNav .thumb:hover img {
  border: 2px solid var(--light);
  background-color: var(--light);
}

.sortableHelper {
  z-index: 20;
  list-style: none;
}

#presNav .thumb:hover .thumbSmart {
  border: 2px solid var(--light);
  background-color: var(--light);
}

.thumb img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.thumbLoading {
  margin: 5px 0px 10px 55px;
  text-align: center;
  padding: 50px 0px;
  background: var(--dusk);
  border: 2px solid var(--dark);
}

/* ///////////////////////////////////////////////////////////////////////////// */
