@charset "UTF-8";
/* CSS DOC */

html {
  overflow-y: auto !important;
}

* {
  --white: #fff;
  --light: #f0f7fb;
  --mid: #f0f7fb;
  --dusk: #6d95c1;
  /* --dusk2: #2e3c5a; */
  --dark: #1e2941;
  /* --dark: #262d40; */

  --teal: #a0f9e7;
  --tealMid: #bcffe2;
  --tealLight: #d3ffec;

  --green: #b9f9a0;
  --greenMid: #cdfeba;
  --greenLight: #e4fcda;

  --yellow: #fff498;
  --yellowMid: #fcf6c3;
  --yellowLight: #fffbd9;

  --orange: #ffc298;
  --orangeMid: #ffd7bb;
  --orangeLight: #ffeee3;

  --red: #ff8690;
  --redMid: #ffb1b8;
  --redLight: #ffe0e2;

  --pink: #ffafe5;
  --pinkMid: #fed2f0;
  --pinkLight: #ffe7f7;

  --purple: #d4aaff;
  --purpleMid: #e2c6ff;
  --purpleLight: #f1e3ff;

  --blue: #2b50ff;
  --cyan: #a0f9e7;
  /* --blue: #85ebff; */
  --blueMid: #aef2ff;
  --blueLight: #d4f8ff;

  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

/*  - - - - - - - - - - - - - - - - - MOBILE */
/* 
@media only screen and (max-width: 700px) {
} */

/*  - - - - - - - - - - - - - - - - - DESKTOP */

body {
  background: var(--white) !important;
  font-size: 15px;
  font-family: Raleway-Medium;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}

/* TEXT */

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9,
h10 {
  font-family: "Poppins", serif !important;
  line-height: 115%;
  font-weight: 500 !important;
}

h1 {
  font-size: 20px !important;
  margin-bottom: 2px;
}

h2 {
  font-size: 30px !important;
  margin-bottom: 2px;
}

h3 {
  font-size: 35px !important;
  margin-bottom: 5px;
}

h4 {
  font-size: 54px !important;
  margin-bottom: 5px;
}

h5 {
  font-size: 64px !important;
  margin-bottom: 5px;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* CTA */

.cta {
  background-color: var(--blue);

  color: var(--pink) !important;
  display: inline-block;
  font-family: "Poppins", serif !important;

  line-height: 28px;
  text-align: center;
  cursor: pointer;
  padding: 6px 12px !important;
  font-size: 17px;
  text-decoration: none !important;
  outline: none;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /*  Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.cta:hover {
  background: var(--blue);
  color: var(--white) !important;

  transition: ease-in-out;
  transform: scale(1.1);
  transition-duration: 0.25s;
}

.cta svg {
  vertical-align: middle;
  height: 20px;
  margin: -1px 1px 0px -3px;
}

.ctaFullWidth {
  width: -webkit-fill-available;
  margin-bottom: 10px;
}

.nextToButtonRight {
  margin: 0px 12px 0px 0px;
  line-height: 38px;
  color: var(--blue) !important;
}

.nextToButtonRight:hover {
  color: var(--dark) !important;
  text-decoration: underline;
}

.nextToButtonLeft {
  margin: 0px 0px 0px 12px;
  line-height: 38px;
  color: var(--blue) !important;
}

.nextToButtonLeft:hover {
  color: var(--dark) !important;
  text-decoration: underline;
}

.ctaRed .cta {
  background: var(--red) !important;
}

.ctaRed .ctaDark {
  background: var(--red) !important;
}

/* MINOR */

.ctaMinor {
  color: var(--dusk) !important;
  background-color: var(--mid) !important;
}

.ctaMinor svg {
  color: var(--dusk) !important;
}

.ctaMinor:hover {
  color: var(--white) !important;
  background-color: var(--blue) !important;
}

.ctaMinor:hover svg {
  color: var(--white) !important;
}

.minor {
  color: var(--dusk);
  line-height: 140%;
}

.minor a {
  color: var(--dusk) !important;
  text-decoration: underline;
}
.minor a:hover {
  color: var(--dark) !important;
}
#modal .minor a:hover {
  color: var(--dark) !important;
}

.altLayout svg {
  float: right;
  margin: 2px -7px 0px 3px;
}

.ctaCircle {
  background: var(--blue);
  color: var(--pink) !important;
  display: block;
  height: 42px;
  width: 42px;
  cursor: pointer;
  border-radius: 50%;
  line-height: 42px;
  text-align: center;
}

.ctaCircle:hover {
  color: var(--white) !important;

  transform: scale(1.1);
  transition-duration: 0.2s;
}

.ctaCircle svg {
  margin: 9px 0px 0px 0px;
}

.ctaDark {
  background: var(--dark);
  color: var(--white) !important;
}

.ctaDark:hover {
  background: var(--dusk) !important;
  color: var(--dark) !important;
}

/* LINKS */

a {
  color: var(--dark) !important;
  outline: none;
  cursor: pointer;
}

a:hover {
  color: var(--blue) !important;
}

/* NAV */

#nav a {
  font-family: "Poppins", serif !important;
  display: block;
  text-decoration: none;

  line-height: 24px;
  text-align: center;
  padding: 8px 0px;
  font-size: 16px;
  float: right;
  margin-left: 22px;
}

#nav a:hover {
  text-decoration: underline !important;
  color: var(--dusk) !important;
}

#nav .cta {
  text-decoration: none !important;
}

#nav .cta:hover {
  text-decoration: none !important;
  color: var(--dark) !important;
}

/* DARK */

/* 
.dark,
.dark a,
.dark p {
  color: var(--white);
} */

/* .dark a:hover {
  color: var(--blue);
} */

.back .cta {
  background: var(--dark) !important;
}

.back .cta:hover {
  color: var(--blue) !important;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /*  Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@media only screen and (max-width: 700px) {
  /* NAV */

  #nav a {
    margin-left: 15px;
  }
}
