.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 25px auto;
}

.spinnerMessageCon {
  background-color: #7cfc00;
  width: fit-content;
  margin: auto;
}
.spinnerMessage {
  width: 40px;
  height: 40px;

  position: relative;
  float: right;
}

.loadingMessage {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}

.spinnerFullScreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: var(--white);
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--blue);
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce1FullScreen,
.double-bounce2FullScreen {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid var(--cyan);
  opacity: 0.85;
  position: absolute;
  /* margin: auto; */
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: var(--blue);
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
