@charset "UTF-8";
/* CSS DOC */

/* MOBILE */
@media only screen and (max-width: 700px) {
}

/* DESKTOP */

.inputFile,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input {
  font-family: "Poppins", serif !important;
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;

  color: var(--dark);

  /* background: var(--dark); */
  width: 100%;
  border: 0px;
  border-bottom: solid var(--cyan) 2px;
  line-height: 18px;
  size: 16px;
  outline: 0;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 10px 0px;

  margin-bottom: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
  -webkit-text-fill-color: var(--dusk);
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--dusk);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--dusk);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--dusk);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--dusk);
}

input[type="file"] {
  display: none;
  color: transparent;
}

.invalid {
  border-bottom: solid var(--red) 2px !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.inputFile {
  font-family: "Catamaran", sans-serif;
  font-weight: 500;
  -webkit-box-shadow: none !important;
  -webkit-text-fill-color: none !important;
  background: var(--blue);
  color: var(--white);
  padding: 20px 15px;
  width: 100%;
  display: block;
  border-radius: 4px;

  -webkit-margin-before: 0px;
  -webkit-margin-after: 8px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

.inputFile:hover {
  color: var(--dark);

  transform: scale(1.1);
  transition-duration: 0.5s;
}

label {
  color: var(--white);
  padding-top: 15px;
  height: 0px;
  display: block;
  margin-bottom: -5px;
  transition: 0.5s;
}

label.invalid {
  color: var(--red);
  opacity: 1;
  height: 20px;
}

.inputLight:-webkit-autofill,
.inputLight:-webkit-autofill:hover,
.inputLight:-webkit-autofill:focus,
.inputLight {
  color: var(--dark) !important;
  background-color: var(--white);
  -webkit-text-fill-color: var(--dark) !important;

  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}

.inputLight::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--dusk);
}
.inputLight::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--dusk);
}
.inputLight:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--dusk);
}
.inputLight:-moz-placeholder {
  /* Firefox 18- */
  color: var(--dusk);
}

#modalFooter #feedback {
  color: var(--red);
  padding-bottom: 15px;
  min-height: 20px;
}
