@charset "UTF-8";
/* CSS DOC */

/* MOBILE //////////////////////////////////////////////////// */

@media only screen and (max-width: 700px) {
  #header {
    /* background: red !important; */
  }
  .column4 {
    width: calc(100%) !important;
  }
}

/* TABLET //////////////////////////////////////////////////// */

@media only screen and (min-width: 700px) {
  #header {
    /* background: blue !important; */
  }

  .columnSection {
    margin-left: -20px;
  }

  .column4 {
    margin-left: 20px;

    width: calc((100% / 2) - 20px);
  }
}

/* DESKTOP //////////////////////////////////////////////////// */

@media only screen and (min-width: 1000px) {
  #header {
    /* background: teal !important; */
  }
  .column4 {
    width: calc((100% / 4) - 20px);
  }
}

#header {
  background: var(--white);
  width: 100%;
  position: absolute;
  top: 0px;
  height: 65px;
  /* background-color: lawngreen; */
}

#logo {
  float: left;
  display: block;
  height: 45px;
  margin: 10px 20px;
  cursor: pointer;
}
#nav {
  float: right;
  display: block;
  margin-right: 20px;
  padding-top: 13px;
}

#nav .cta {
  float: right;
  margin: 0px !important;
}

/* MAIN */

#main {
  padding: 115px 15px 15px 15px;
  overflow-x: visible;
  min-height: calc(100vh - 130px);
  max-width: 1000px;
  margin: auto;
}

.mainTop {
  padding: 65px 15px 15px 15px !important;
}

/* SECTION */

.section {
  padding: 0px 0px 150px 0px;
  transition-duration: 0.25s;
  /* background-color: greenyellow; */
}

.sectionHeader {
  margin: 0px 0px 60px 0px;
}

.subSection {
  margin: 0px 0px 60px 0px;
  transition-duration: 0.25s;
  display: inline-block;
  width: 100%;
}
.subSectionHeader {
  margin: 0px 0px 15px 0px;
}

/* COLUMN */

.columnSection {
  display: inline-block;
  width: calc(100% + 20px);
}

.column2 {
  width: calc((100% / 2) - 20px);
  margin-left: 20px;
  float: left;
}

.column3 {
  width: calc((100% / 3) - 20px);
  margin-left: 20px;
  float: left;
}

.column3-2 {
  width: calc(((100% / 3) * 2) - 20px);
  margin-left: 20px;
  float: left;
}

.column4 {
  float: left;
}

/* OTHER */

.center {
  max-width: 1000px;
  margin: 0px auto 0px auto;
}
