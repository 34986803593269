@charset "UTF-8";
/* CSS DOC */

/* MOBILE */
@media only screen and (max-width: 700px) {
}

/* DESKTOP */

#modal {
  background: var(--white);
  color: var(--dark);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  position: absolute;
  z-index: 10000000;
  min-height: 100vh;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  overflow-x: hidden;
}

#modal h1,
#modal h2,
#modal h3,
#modal h4,
#modal h5,
#modal h6,
#modal h7,
#modal h8,
#modal h9,
#modal h10 {
  color: var(--dark);
}

.back {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 15px;
  z-index: 2;
}
.back:hover a {
  cursor: pointer;
  color: var(--blue) !important;
}
.close {
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 15px;
  z-index: 1;
}

.close:hover a {
  cursor: pointer;
  /* color: var(--blue) !important; */
}

.icon svg {
  padding-top: 10px;
}

#modalCenter {
  width: 330px;
}

/* #modal a {
  color: var(--mid);
}

#modal a:hover {
  color: var(--white);
}

#modal .cta {
  color: var(--dark);
}

#modal .cta:hover {
  color: var(--dark);
} */

.modalMessage {
  padding: 8px;
  padding: 30px 0px;
}

.modalMessage h3 {
  color: var(--white);
}

.modalContents {
}
.modalFooter {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: right;
}

.action {
  padding: 30px 0px;
  text-align: right;
}

/* //////////////////////////////////////////////////// */
/* FULLSCREEN MODAL */

.modalFullscreen {
  display: inline;
}
.centerFullscreen {
  width: 100% !important;
  /* position: absolute; */
  top: 0px;
  background-color: var(--white);
}

.doneFullscreen {
  /* position: absolute; */
  float: right;
}

.messageFullscreen {
  background-color: var(--white);

  text-align: center;
  height: 50px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}
