@charset "UTF-8";
/* CSS DOC */

.overviewTools {
  display: inline-block;
  height: inherit;
  margin: 0px 0px 10px 0px;
}

.overviewTools a {
  color: var(--dark) !important;
}

.overviewTools a:hover {
  color: var(--dusk) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mid);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--mid);
}
